import {computed, ref} from "vue";
import IOrganization from "~/cabinet/ts/data/organization/IOrganization";
import IUser from "~/cabinet/ts/data/organization/IUser";
import {IUsluga} from "~/cabinet/ts/data/usluga/IUsluga";
import ICurrency from "~/cabinet/ts/data/ICurrency";
import Dictionary from "~/ts/library/Dictionary";
import {SUPER_DEALER_ID} from "~/cabinet/ts/Constant";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ACCESS_AGENT, ACCESS_DEALER} from "~/cabinet/ts/data/organization/IUserAccess";
import BadgeManager from "~/cabinet/vue/interface/menu/BadgeManager";
import DateHelper from "~/ts/library/Date";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import CabinetAccessWrapper from "~/cabinet/ts/store/CabinetAccessWrapper";
import {PHONEBOOK_CONTACTS_ROUTE} from "~/cabinet/ts/routes/Constants";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {
    DiscountCouponInterface
} from "~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCouponFormInterface";
import {IOperation} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import {Icon} from "~/cabinet/vue/interface/icon/IconInterface";


let data = ref<IInitialAppData>();
let organization = computed(() => data.value?.organization);
let availableUslugiList = computed(() => data.value?.uslugi ?? []);
let user = computed(() => data.value?.user);
let currencyList = computed(() => data.value?.currency ?? {});
let balance = computed(() => data.value?.balance);
let credit = computed(() => data.value?.credit);
let details = computed(() => data.value?.details ?? []);
let dealerParams = computed(() => data.value?.dealer);
let leftMenu = computed(() => data.value?.leftMenu ?? []);
let noticeChannels = computed(() => data.value?.messengerNotice.channels ?? []);
let dealerCabinetConfig = computed(() => data.value?.dealerCabinetConfig);
let isOurChatProject = computed(() => {
    let href = window.location.href.toLowerCase();
    return href.indexOf(".verbox.ru") > -1 || href.indexOf(".talk-me.ru") > -1;
});
let isAuthorized = computed(() => {
    return !!user.value;
});
let isSandbox = computed(() => {
    return data.value ? data.value.isSandbox : (window.location.port == "8088");
});
let isFromOrgCard = computed(() => {
    return data.value ? data.value.isFromOrgCard : false;
});
let isNewDoc = computed(() => {
    return data.value?.isNewDoc ?? false;
});
let currencyArray = computed(() => {
    return ObjectHelper.arrayFromDictionary(currencyList.value);
});
let isDealer = computed(() => {
    return !!(organization.value?.isDealer);
});
let isSuperDealer = computed(() => {
    return organization.value?.ocode === "80000213986";
});
let isSuperDealerClient = computed(() => {
    return organization.value?.dealerId === SUPER_DEALER_ID;
});
let organizationCurrency = computed(() => {
    return organization.value ? currencyList.value[organization.value.currencyId] : null;
});
let uslugiDictionary = computed(() => {
    return ObjectHelper.dictionaryFromArray(availableUslugiList.value);
});
let senderList = computed(() => {
    return availableUslugiList.value.filter(usluga => usluga.isSender);
});
let senderDictionary = computed(() => {
    return ObjectHelper.dictionaryFromArray(senderList.value);
});
let access = computed(() => {
    return new CabinetAccessWrapper(user.value.access, user.value.isAdmin, data.value?.dealerCabinetConfig.isNewsEnabled);
});

function hasAccess(access: string): boolean {
    return user.value && (user.value.access as any as Dictionary<boolean>)[access];
}

function becomeAgent() {
    user.value.access[ACCESS_AGENT] = true;
}

let paymentSystemsForConfig = computed(() => {
    return data.value?.paymentSystemsForConfig ?? [];
});
let kassaSystemsForConfig = computed(() => {
    return data.value?.kassaSystemsForConfig ?? [];
});
let contactsSectionDescr = computed(() => {
    let section = dealerCabinetConfig.value.menu.sections[PHONEBOOK_CONTACTS_ROUTE];
    return section ? section.descr : "Контакты";
});

export const AccountStore = {
    quit: async () => {
        let result = await LcabApiRequest.fetch({
            url: "/api/account/quit"
        });
        result.showMessage();
        if (result.isSuccess) {
            window.location.reload();
        }
    },
    setInitialAppData: (initialAppData: IInitialAppData) => {
        data.value = initialAppData;
        MyFilterHelper.setOperations(initialAppData.filterOperations);
        if (initialAppData.CSRF) {
            CsrfToken.set(initialAppData.CSRF);
        }

        if (initialAppData.onlineChatSetClientInfoPayload) {
            let win = window as any;
            let chat = win.MeTalk;
            if (!chat) {
                chat = win.TalkMe;
                if (!chat) {
                    chat = win.Verbox;
                    if (!chat) {
                        chat = win.OnlineChat;
                    }
                }
            }
            if (typeof chat == "function") {
                chat("setClientInfo", initialAppData.onlineChatSetClientInfoPayload);
            }
        }

        DateHelper.setTimeZoneOffset(initialAppData.timeZoneOffset);

        let badgeInterval = 60000;

        if (initialAppData.user && (initialAppData.user.access as any)[ACCESS_DEALER]) {
            badgeInterval = 10000;
        }
        BadgeManager.setInterval(badgeInterval);
    },
    organization,
    availableUslugiList,
    user,
    currencyList,
    balance,
    credit,
    details,
    dealerParams,
    leftMenu,
    noticeChannels,
    dealerCabinetConfig,
    isOurChatProject,
    isAuthorized,
    isSandbox,
    isNewDoc,
    currencyArray,
    isDealer,
    isSuperDealer,
    isSuperDealerClient,
    organizationCurrency,
    uslugiDictionary,
    senderList,
    senderDictionary,
    access,
    hasAccess,
    becomeAgent,
    paymentSystemsForConfig,
    kassaSystemsForConfig,
    contactsSectionDescr,
    data,
    contactbookDataSourceList: computed(() => {
        return data.value?.contactBookDataSourceList ?? [];
    }),
    defaultContactbookDataSourceId: computed(() => {
        return data.value?.contactBookDataSourceList[0]?.id
    })
}


interface IInitialAppDealerParams {
    marketing: {
        actions: { id: string, descr: string }[]
    },
    mysqlHosts?: {
        id: string
    }[],
    phpHosts?: {
        id: string;
        descr: string;
    }[]
}

export interface IInitialAppData {
    CSRF: string;
    uslugi: IUsluga[];
    user: IUser;
    organization: IOrganization;
    currency: Dictionary<ICurrency>;
    balance: number;
    credit?: number;
    details: IDetails[],
    dealer?: IInitialAppDealerParams,
    leftMenu: ILeftMenu[],
    timeZoneOffset: number,
    messengerNotice: {
        channels: INoticeChannel[]
    },
    onlineChatSetClientInfoPayload?: any,
    dealerCabinetConfig: IDealerCabinetConfig,
    agentHandlerTypes: AgentHandlerTypeInterface[];
    allAgentHandlerTypes: AgentHandlerTypeInterface[];
    paymentSystemsForConfig: { id: string, descr: string }[],
    kassaSystemsForConfig: { id: string, descr: string }[],
    isSandbox: boolean,
    isFromOrgCard: boolean,
    isNewDoc: boolean,
    htmlContent: {
        footer: string;
        bodyStart: string;
        headStart: string;
        authPage: string;
        leftMenu: string;
    },
    onlineDiscountCoupon?: DiscountCouponInterface,
    filterOperations: Dictionary<IOperation>,
    contactBookDataSourceList: ContactBookDataSourceInterface[],
    messengerPlatformTypeId: string
}

export interface AgentHandlerTypeInterface {
    id: string,
    descr: string,
    icon: string
}

export interface ContactBookDataSourceInterface {
    id: string;
    descr: string;
    icon: Icon;
    isCabinetContactbook: boolean;
    isGroupEditable: boolean;
    isContactRegisterEditable: boolean;
    isContactRegisterCreateAllowed: boolean;
    isContactRegisterRemovable: boolean;
    isFieldsDependsOnGroupId: boolean;
    isContactMessengerSubscribeAllowed: boolean;
}

export interface IDealerCabinetConfig {
    menu: {
        collapseSenders: boolean,
        sections: Dictionary<{
            descr?: string,
            icon?: string,
            weight: number
        }>
    },
    isNewsEnabled: boolean,
    isRegFormEnabled: boolean,
    isRemindEnabled: boolean,
    css: string,
    oAuthProviders: {
        id: string;
        descr: string;
        authorizationUrl: string;
        icon: Icon;
    }[];
}

export interface INoticeChannel {
    id: string;
    icon: string;
    descr: string;
    link: string;
    color: string;
}

interface ILeftMenu {
    descr: string,
    href: string
}

interface IDetails {
    value: string;
    descr: string;
    isSuccess: boolean;
}
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'text-input-with-variables-root': true,
    inline: _vm.inline
}},[(_setup.isTextarea && (_vm.$slots.left || _vm.$slots.right))?_c('el-row',{staticClass:"mb-1"},[_c('el-col',{attrs:{"span":_vm.leftSpan}},[_vm._t("left"),_vm._v(" "),(!_vm.$slots.left)?_c('div',[_vm._v(" ")]):_vm._e()],2),_vm._v(" "),_c('el-col',{staticClass:"text-right",attrs:{"span":24 - _vm.leftSpan}},[_vm._t("right")],2)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"position-relative"},[_c('div',{class:_setup.inputClassName},[_c('div',{class:{
                    [_setup.inputInnerClassName]: true,
                    textarea: _setup.isTextarea,
                    'show-content-variables': _setup.showContentVariables
                }},[(_setup.active)?_c(_setup.TinyMceEditor,{key:_setup.editorKey,ref:"editor",class:{
                         [_setup.inputInnerClassName]: _setup.isTextarea,
                        'tinymce-for-input-with-variables': true,
                        'single-line': _setup.isOneLiner,
                        'disabled': _vm.disabled,
                        [_vm.editorClassList.join(' ')]: true
                    },style:(_setup.isTextarea || (_vm.inline && _vm.inlineLimitedHeight) ? {
                        minHeight: _vm.minHeight ? `${_vm.minHeight}px` : null,
                        maxHeight: _vm.maxHeight ? `${_vm.maxHeight}px` : null,
                    } : null),attrs:{"value":_setup.model,"init":_setup.getSettings(),"disabled":_vm.disabled,"model-events":"change input undo redo paste","id":_setup.uid,"spellcheck":"true"},on:{"input":_setup.onInput},nativeOn:{"!paste":function($event){return _setup.onTinyMcePaste.apply(null, arguments)}}}):_vm._e()],1)]),_vm._v(" "),(_setup.showContentVariables)?_c('div',{class:{
                'content-variables-wrapper': true,
                'has-overflow': _setup.hasOverflow
            }},[_c(_setup.ContentVariables,{ref:"contentVariables",attrs:{"hidden":_vm.inline,"variable-groups":_setup.variableGroups},on:{"paste":_setup.paste}},[_c('el-tooltip',{attrs:{"content":"Подставить значение"}},[_c('el-button',{staticClass:"paste-button",attrs:{"type":"text"}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"paste"}})],1)],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
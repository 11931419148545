var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-popover',{class:`${_vm.paddingClass} d-flex flex-column big-icon-flex-button`,style:({minWidth: _vm.minWidth}),attrs:{"disabled":!_vm.$slots.popover,"popper-class":_vm.popperClass,"placement":_vm.popoverPlacement,"trigger":_vm.popoverTrigger},model:{value:(_setup.popoverModel),callback:function ($$v) {_setup.popoverModel=$$v},expression:"popoverModel"}},[_c('el-button',{class:{
            [`text-center d-flex ml-0 mr-0 w-100 h-100 position-relative  button ${_vm.buttonClass}`]: true,
            selectable: _vm.selectable,
            selected: _vm.selected,
            'with-shadow': _vm.shadow
        },attrs:{"slot":"reference","disabled":_vm.disabled,"type":_vm.buttonType,"plain":_vm.plain},on:{"click":function($event){_vm.$nextTick(() => _vm.$nextTick(() => _setup.emits('click')))}},slot:"reference"},[(_vm.removable)?_c('el-button',{staticClass:"position-absolute",style:({
                top: '-.5em',
                right: '-.8em'
            }),attrs:{"type":"danger","circle":"","size":"mini"},on:{"click":function($event){return _setup.emits('remove')}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"times"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-100"},[(_vm.icon || _vm.$slots.icon)?_c('h3',{staticClass:"position-relative"},[(_vm.badge)?_c('el-badge',{staticClass:"badge",attrs:{"type":"danger","value":_vm.badge}}):_vm._e(),_vm._v(" "),(!_vm.icon)?_vm._t("icon"):_c(_setup.FontAwesomeIcon,{class:_vm.iconColorClass ? `text-${_vm.iconColorClass}` : null,style:(_setup.iconStyle),attrs:{"icon":_vm.icon,"light":""}})],2):_vm._e(),_vm._v(" "),(_vm.descr)?_c('div',{staticStyle:{"white-space":"normal","word-break":"break-word"}},[_c('div',{staticClass:"button-descr"},[_vm._v("\n                    "+_vm._s(_vm.descr)+"\n                ")]),_vm._v(" "),(_vm.extraDescr || _vm.$slots.extraDescr)?_c('div',{staticClass:"text-muted font-size-small mt-1"},[_vm._v("\n                    "+_vm._s(_vm.extraDescr)+"\n                    "),_vm._t("extraDescr")],2):_vm._e(),_vm._v(" "),(_vm.$slots.popover && _vm.showPopoverArrow)?_c('div',{staticClass:"mt-1 lh-primary"},[_c(_setup.FontAwesomeIcon,{staticClass:"text-muted popover-chevron",attrs:{"icon":"chevron-down"}}),_vm._v(" "),_c('span',{staticClass:"popover-info text-muted d-none"},[_vm._v("\n                        Клик для разворота\n                    ")])],1):_vm._e()]):_vm._e()])],1),_vm._v(" "),_vm._t("popover",null,{"slot":"default"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Dictionary from "~/ts/library/Dictionary";
import {USLUGA_ID_ONLINE, USLUGA_ID_RECEIVESMS} from "~/cabinet/ts/Constant";

export default class CabinetAccessWrapper {
    constructor(private values: Dictionary<boolean>, private _isAdmin: boolean, private isNewsEnabled: boolean) {
        this.values = values;
    }

    private check(id: string) {
        return this.isAdmin || !!this.values[id];
    }

    private getUslugaPrefix(uslugaId: string): string {
        return `usluga/${uslugaId}/`;
    }

    public checkAccessForUslugaSection(uslugaId: string): boolean {
        return this.check(`${this.getUslugaPrefix(uslugaId)}section`);
    }

    /** SENDER ACCESS BEGIN */

    public checkAccessForSenderSend(uslugaId: string) {
        return this.check(`${this.getUslugaPrefix(uslugaId)}send`);
    }

    public checkAccessForSenderReport(uslugaId: string) {
        return this.check(`${this.getUslugaPrefix(uslugaId)}report`);
    }

    public checkAccessForSenderViewReportRecipientId(uslugaId: string): boolean {
        return this.check(`${this.getUslugaPrefix(uslugaId)}viewReportRecipientId`);
    }

    public checkAccessForSenderOtherUsersReports(uslugaId: string): boolean {
        return this.check(`${this.getUslugaPrefix(uslugaId)}otherUsersReports`);
    }

    public checkAccessForSenderUseAllTemplates(uslugaId: string): boolean {
        return this.check(`${this.getUslugaPrefix(uslugaId)}useAllTemplates`);
    }

    public checkAccessForSenderChangeChannel(uslugaId: string): boolean {
        return this.check(`${this.getUslugaPrefix(uslugaId)}changeChannel`);
    }

    /** SENDER ACCESS END */

    /** ONLINE ACCESS BEGIN */

    private get onlinePrefix() {
        return this.getUslugaPrefix(USLUGA_ID_ONLINE);
    }

    public checkAccessForOnlineConfig(): boolean {
        return this.check(`${this.onlinePrefix}config`);
    }

    public checkAccessForOnlineManageRates(): boolean {
        return this.check(`${this.onlinePrefix}manageRates`);
    }

    public checkAccessForOnlineRemoveDialogs(): boolean {
        return this.check(`${this.onlinePrefix}removeDialogs`);
    }

    /** ONLINE ACCESS END */

    /** RECEIVESMS ACCESS BEGIN */

    public checkAccessForReceiveSmsManageNumbers(): boolean {
        return this.check(`${this.getUslugaPrefix(USLUGA_ID_RECEIVESMS)}manageNumbers`);
    }

    /** RECEIVESMS ACCESS END */

    /** CONTACTBOOK ACCESS BEGIN */

    private get contactbookPrefix() {
        return "contactbook/";
    }

    public checkAccessForContactbookSection(): boolean {
        return this.check(`${this.contactbookPrefix}section`);
    }

    public checkAccessForContactbookViewRecipientId(): boolean {
        return this.check(`${this.contactbookPrefix}viewRecipientId`);
    }

    public checkAccessForContactbookAddContact(): boolean {
        return this.check(`${this.contactbookPrefix}addContact`);
    }

    public checkAccessForContactbookEditContact(): boolean {
        return this.check(`${this.contactbookPrefix}editContact`);
    }

    public checkAccessForContactbookDeleteContact(): boolean {
        return this.check(`${this.contactbookPrefix}deleteContacts`);
    }

    public checkAccessForContactbookAddGroup(): boolean {
        return this.check(`${this.contactbookPrefix}addGroup`);
    }

    public checkAccessForContactbookEditGroup(): boolean {
        return this.check(`${this.contactbookPrefix}editGroup`);
    }

    public checkAccessForContactbookDeleteGroup(): boolean {
        return this.check(`${this.contactbookPrefix}deleteGroup`);
    }

    public checkAccessForContactbookOtherUsersGroup(): boolean {
        return this.check(`${this.contactbookPrefix}otherUsersGroups`);
    }

    public checkAccessForContactbookEditStopList(): boolean {
        return this.check(`${this.contactbookPrefix}editStopList`);
    }

    public checkAccessForContactbookClear(): boolean {
        return this.check(`${this.contactbookPrefix}clear`);
    }

    public checkAccessForContactbookExportContacts(): boolean {
        return this.check(`${this.contactbookPrefix}exportContacts`);
    }

    /** CONTACTBOOK ACCESS END */

    /** OTHER ACCESS BEGIN */

    private get otherPrefix() {
        return "other/";
    }

    public checkAccessForDashboardConfig(): boolean {
        return this.check(`${this.otherPrefix}dashboardConfig`);
    }


    public checkAccessForTarifInfo(): boolean {
        return this.check(`${this.otherPrefix}tarifInfo`);
    }

    public checkAccessForPm(): boolean {
        return this.check(`${this.otherPrefix}pm`);
    }

    public checkAccessForNotifications(): boolean {
        return this.check(`${this.otherPrefix}notifications`);
    }


    public checkAccessForViewNews(): boolean {
        return this.check(`${this.otherPrefix}viewNews`) && this.isNewsEnabled;
    }

    public checkAccessForChangePassword() {
        return this.check(`${this.otherPrefix}changePassword`);
    }

    public checkAccessForLcabDocuments(): boolean {
        return this.check(`${this.otherPrefix}lcabDocuments`);
    }

    public checkAccessForLcabStickers(): boolean {
        return this.check(`${this.otherPrefix}lcabStickers`);
    }

    public checkAccessForLcabCoupon(): boolean {
        return this.check(`${this.otherPrefix}lcabCoupon`);
    }


    /** OTHER ACCESS END */

    /** DEALER ACCESS BEGIN */

    private get dealerPrefix(): string {
        return "dealer/";
    }


    public checkAccessForDealerModifyContent(): boolean {
        return this.check(`${this.dealerPrefix}modifyContent`);
    }

    public checkAccessForDealerConfig(): boolean {
        return this.check(`${this.dealerPrefix}config`);
    }

    public checkAccessForDealerTarifConfig(): boolean {
        return this.check(`${this.dealerPrefix}tarifConfig`);
    }

    /*
    public checkAccessForDealerManageAllOrgs(): boolean {
        return this.check(`${this.dealerPrefix}manageAllOrgs`);
    }
     */

    public checkAccessForDealerUnfinishedReg(): boolean {
        return this.check(`${this.dealerPrefix}unfinishedReg`);
    }

    public checkAccessForDealerOrgList(): boolean {
        return this.check(`${this.dealerPrefix}orgList`);
    }

    public checkAccessForDealerOrgListExport(): boolean {
        return this.check(`${this.dealerPrefix}orgListExport`);
    }

    public checkAccessForDealerChangeTarif(): boolean {
        return this.check(`${this.dealerPrefix}changeTarif`);
    }

    public checkAccessForDealerManageClientLoginPassword(): boolean {
        return this.check(`${this.dealerPrefix}manageClientLoginPassword`);
    }

    public checkAccessForDealerDopModules(): boolean {
        return this.check(`${this.dealerPrefix}dopModules`);
    }

    public checkAccessForDealerMakePayment(): boolean {
        return this.check(`${this.dealerPrefix}makePayment`);
    }

    public checkAccessForDealerFinReports(): boolean {
        return this.check(`${this.dealerPrefix}finReports`);
    }

    public checkAccessForDealerDoc(): boolean {
        return this.check(`${this.dealerPrefix}doc`);
    }

    public checkAccessForDealerEvents(): boolean {
        return this.check(`${this.dealerPrefix}events`);
    }

    public checkCabinetAccess(): boolean {
        return !!this.values.cabinet;
    }

    public checkDealerConfigOwn(uslugaId: string): boolean {
        return !!this.values[`usluga_canConfigOwn_${uslugaId}`];
    }

    /** DEALER ACCESS END */


    public get isAdmin(): boolean {
        return this._isAdmin;
    }
}
import AbstractUploadHelper, {IUploadFileResult} from "~/ts/library/AbstractUploadHelper";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";

export default class CabinetUploadHelper extends AbstractUploadHelper {
    getUrl(): string {
        return LcabApiRequest.getUrl(super.getUrl());
    }

    getHandler(onSuccess: (idsList: string[], list: IUploadFileResult[]) => void, onError?: (result: LcabApiResult<any>) => void) {
        return (file: any, fileList: any) => {
            if (file.response && file.response.code != 1) {
                let result = new LcabApiResult(file.response);
                result.showMessage();
                if (onError) {
                    onError(result);
                }
            } else {
                onSuccess(this.getIdFromFileList(fileList), this.getResultsFromFileList(fileList));
            }
        }
    }

    public getResultsFromFileList(fileList: { response: any }[]): IUploadFileResult[] {
        let result: IUploadFileResult[] = [];
        for (let item of fileList) {
            let resp = item.response;
            if (resp) {
                if (resp.code == 1 && resp.data && resp.data.id) {
                    result.push({
                        id: resp.data.id,
                        finalUrl: resp.data.finalUrl,
                        url: resp.data.url,
                        filename: resp.data.filename,
                        params: resp.data.params,
                        size: resp.data.size
                    });
                }
            }
        }
        return result;
    }
}
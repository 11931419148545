import {__} from "~/ts/library/Translate";
import Dictionary from "~/ts/library/Dictionary";
import Url from "~/ts/library/Url";
import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";

export interface IUploadFileResult {
    id: string,
    finalUrl: string,
    url: string,
    filename: string;
    size: number;
    params?: {
        width?: number,
        height?: number
    }
}

export default abstract class AbstractUploadHelper {
    protected tag: string;
    protected longStore: boolean;
    private convertTo: string;
    private maxWidth: number;

    public static sizeToString(sizeKb: number) {
        sizeKb = Math.round(sizeKb);
        if (sizeKb < 1000) {
            return sizeKb + " " + __('Кб');
        }
        sizeKb = Math.round(sizeKb * 100 / 1000) / 100;
        return sizeKb + " " + __('Мб');
    }

    public setTag(tag: string) {
        this.tag = tag;
        return this;
    }

    public setMaxWidth(maxWidth: number) {
        this.maxWidth = maxWidth;
        return this;
    }


    public setLongStore() {
        this.longStore = true;
        return this;
    }

    public setConvertTo(extension: string) {
        this.convertTo = extension;
        return this;
    }

    abstract getHandler(onSuccess: (idsList: string[], list: IUploadFileResult[]) => void, onError?: (result: LcabApiResult<any>) => void): (file: any, fileList: any) => void;

    public getUrl() {
        let query: Dictionary<any> = {};
        if (this.tag) {
            query.tag = this.tag;
        }
        if (this.longStore) {
            query.longstore = 1;
        }
        if (this.convertTo) {
            query.convert = this.convertTo;
        }
        if (this.maxWidth) {
            query.maxwidth = this.maxWidth;
        }

        return "/api/upload?" + Url.toQueryString(query);
    }

    public getIdFromFileList(fileList: { response: any }[]): string[] {
        return this.getResultsFromFileList(fileList).map(item => item.id);
    }

    public abstract getResultsFromFileList(fileList: { response: any }[]): IUploadFileResult[];

    public static getIdWithNameFromFileList(fileList: { name: string, response: any }[]): Dictionary<string> {
        let result: Dictionary<string> = {};
        for (let item of fileList) {
            let resp = item.response;
            if (resp) {
                if (resp.code == 1 && resp.data && resp.data.id) {
                    result[resp.data.id] = item.name;
                }
            }
        }

        return result;
    }

    public static getUrlById(fileId: string | string[]) {
        return "/download.php?f=" + ((typeof fileId == "string") ? fileId : (fileId as string[]).join(";"));
    }
}